<template>
    <div>
        <b-card>
            <b-col class="m-2 w-100">
                <!-- Table Filters -->

                <b-row class="mr-3 mb-2 align-items-end">
                    <b-col class="col-md-12">
                        <b-form-input v-model="filters.search" class="d-inline-block mr-1" placeholder="Search..."
                            @input="onSearch" />
                    </b-col>
                    <!-- Status -->
                </b-row>
                <b-row class="mr-3 mb-2 align-items-end">

                    <!-- Conversion -->
                    <b-col class="d-flex mb-1 mb-md-0 align-items-start justify-content-center flex-column">
                        <label>Is Paid</label>
                        <v-select v-model="filters.is_paid" :options="is_paid_options" :clearable="true"
                            item-text="label" :reduce="(item) => item.value" @input="onSearch"
                            class="per-page-selector d-inline-block w-100" />
                    </b-col>

                    <b-col class="d-flex mb-1 mb-md-0 align-items-start justify-content-center flex-column">
                        <label>Is Fullfilled</label>
                        <v-select v-model="filters.is_fullfilled" :options="is_fullfilled_options" :clearable="true"
                            item-text="label" :reduce="(item) => item.value" @input="onSearch"
                            class="per-page-selector d-inline-block w-100" />
                    </b-col>


                    <b-col class="d-flex align-items-start justify-content-center flex-column mb-1 mb-md-0">
                        <label>Date Range</label>
                        <flat-pickr id="assigned_on" placeholder="Select Date Range" v-model="filters.date_range"
                            @input="onSearch" class="form-control" :config="{
                                dateFormat: 'Y-m-d',
                                altInput: true,
                                altFormat: 'd-m-Y',
                                allowInput: true,
                                mode: 'range',
                            }" />
                    </b-col>
                    <b-col class="d-flex align-items-start justify-content-center flex-column mb-1 mb-md-0">
                        <button class="btn btn-primary w-100" @click="resetFilters">
                            Reset
                        </button>
                    </b-col>
                </b-row>
                <b-row cols="12 mb-2 d-flex justify-content-end">
                    <b-col md="2" class="d-flex align-items-end justify-content-center mb-1 mb-md-0 pr-2">

                    </b-col>
                </b-row>
            </b-col></b-card>
        <b-card>
            <b-table :items="franchises" :fields="franchise_fields" responsive>
                <template #cell(show_orders)="row">
                    <b-form-checkbox v-if="row.item.orders.length > 0" v-model="row.detailsShowing"
                        @change="row.toggleDetails" />
                </template>

                <template #cell(franchise_name)="data">
                    <span class="text-nowrap">{{ data.item.franchise_name }} <small v-if="data.item.franchise_level">({{
                        data.item.franchise_level
                            }})</small></span>
                </template>

                <template #row-details="row">
                    <b-card class="border bg-light-primary" no-body>
                        <b-table :items="row.item.orders" :fields="order_fields" responsive
                            @row-clicked="orderRowClicked">
                            <template #cell(so_id)="data">
                                #{{ data.item.so_id }}
                            </template>

                            <template #cell(transaction_status)="main_data">
                                <div style="width: 180px;">
                                    <template v-if="main_data.item.transaction_status">
                                        <b-badge
                                            :variant="main_data.item.transaction_status === 'success' ? 'success' : main_data.item.transaction_status === 'failed' ? 'danger' : 'warning'">
                                            {{ main_data.item.transaction_status === 'success' ? 'Success' :
                                                main_data.item.transaction_status === 'failed' ? 'Failed' : 'Pending' }}
                                        </b-badge>
                                    </template>
                                    <template v-else>
                                        <b-badge variant="secondary">
                                            Unknown
                                        </b-badge>
                                    </template>
                                </div>
                            </template>

                            <template #cell(invoice)="data">

                                <b-button v-b-tooltip.hover class="bg-transparent" title="Invoice"
                                    @click="invoiceClick(data.item.so_id)"><feather-icon icon="FileIcon"
                                        size="18" /></b-button>

                            </template>

                            <template #cell(so_is_fullfilled)="data">
                                <v-select v-if="user_type === 'counselor' || user_type === 'accounts'" style="width: 180px"
                                    v-model="data.item.so_is_fullfilled" :options="is_fullfilled_options"
                                    @input="() => saveOrderStatus(data.item, 'is_fullfilled')" label="label"
                                    :reduce="(item) => item.value" :clearable="false">
                                    <template #option="data">
                                        <div style="width: 180px;">
                                            <b-badge :variant="data.value === 'Y' ? 'success' : 'danger'">
                                                {{ data.label }}
                                            </b-badge>
                                        </div>
                                    </template>
                                    <template #selected-option="data">
                                        <b-badge :variant="data.value === 'Y' ? 'success' : 'danger'">
                                            {{ data.label }}
                                        </b-badge>
                                    </template>
                                </v-select>
                                <b-badge v-else :variant="data.item.so_is_fullfilled === 'Y' ? 'success' : 'danger'">
                                    {{ data.item.so_is_fullfilled === 'Y' ? 'Fulfilled' : 'Not Fulfilled' }}
                                </b-badge>
                            </template>

                            <template #cell(created_at)="data">
                                <div class="text-nowrap">
                                    <b-badge variant="primary">
                                        {{ moment(data.item.created_at).format("DD MMM, YYYY") }} at
                                        {{ moment(data.item.created_at).format("hh:mm A") }}
                                    </b-badge>
                                </div>
                            </template>
                        </b-table>
                    </b-card>
                </template>

                <template #cell(last_order_date)="data">
                    <div class="text-nowrap" v-if="data.item.last_order_date">
                        <b-badge variant="primary">
                            {{ moment(data.item.last_order_date).format("DD MMM, YYYY") }} at {{
                                moment(data.item.last_order_date).format("hh:mm A") }}
                        </b-badge>
                    </div>
                </template>
            </b-table>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BButton,
    BFormInput,
    BTable,
    BBadge,
    BPagination,
    BRow,
    BCol,
    BFormCheckbox,
    BTooltip,
} from "bootstrap-vue";
import CommonServices from "@/apiServices/CommonServices";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import moment from '@/utils/moment';
import vSelect from "vue-select";
import store from "@/store";
import flatPickr from "vue-flatpickr-component";

export default {
    components: {
        BCard,
        BButton,
        BFormInput,
        BTable,
        BBadge,
        BPagination,
        BRow,
        BCol,
        vSelect,
        BFormCheckbox,
        BBadge,
        flatPickr,
        BTooltip
    },
    data() {
        return {
            orderCount: 0,
            filters: {
                search: '',
                date_range: '',
                product_id: null,
                is_paid: '',
                is_fullfilled: '',
                is_status: ''
            },

            franchises: [],
            franchise_fields: [
                { key: 'show_orders', label: 'Show Orders', class: "text-nowrap" },
                { key: 'franchise_name', label: 'Franchise', class: "text-nowrap" },
                { key: 'order_count', label: 'Orders', class: "text-nowrap" },
                { key: 'last_order_date', label: 'Last Ordered', class: "text-nowrap" },
                { key: 'paid_amount', label: 'Paid Amount', class: "text-nowrap" },
                { key: 'unpaid_amount', label: 'Outstanding Amount', class: "text-nowrap" },
                { key: 'paid_orders', label: 'Paid Orders', class: "text-nowrap" },
                { key: 'unpaid_orders', label: 'Unpaid Orders', class: "text-nowrap" },
                { key: 'fullfilled_orders', label: 'Fullfilled Orders', class: "text-nowrap" },
                { key: 'unfullfilled_orders', label: 'Unfullfilled Orders', class: "text-nowrap" },
                { key: 'paid_but_unfullfilled', label: 'Paid But Unfullfilled Orders', class: "text-nowrap" },
                { key: 'fullfilled_but_unpaid', label: 'Fullilled But Unpaid Orders', class: "text-nowrap" }
            ],
            order_fields: [
                { key: 'so_id', label: 'Order ID' },
                { key: 'sp_name', label: 'Product' },
                { key: 'so_currency', label: 'Currency' },
                { key: 'soi_quantity', label: 'Quantity' },
                { key: 'soi_amount', label: 'Quanity Amount' },
                { key: 'so_addition_charges', label: 'Additional Charges' },
                // { key: 'so_quantity', label: 'Quantity' },
                // { key: 'so_quantity_amount', label: 'Quanity Amount' },
                // { key: 'so_addition_charges', label: 'Additional Charges' },
                { key: 'so_total_amount', label: 'Total Amount' },
                { key: 'transaction_status', label: 'Payment Status' },
                { key: 'so_is_fullfilled', label: 'Order Fulfillment' },
                { key: 'created_at', label: 'Date' },
                { key: 'invoice', label: 'Actions' },

            ],
            is_fullfilled_options: [
                { label: "Fullfilled", value: 'Y' },
                { label: "Not Fullfilled", value: 'N' }
            ],
            is_paid_options: [
                { label: "Paid", value: 'Y' },
                { label: "Not Paid", value: 'N' }
            ],
            status: [
                { label: "Active", value: "Y" },
                { label: "Unactive", value: "N" },
            ],
            debounce: null,
        }
    },
    computed: {
        user_type() {
            if (store.getters["user/getUserDetails"]?.user_type) {
                return store.getters["user/getUserDetails"].user_type;
            } else {
                return null;
            }
        },
    },
    watch: {
        user_type(val) {
            this.getAllFranchisesAndOrders();
        },
    },
    directives: {
        BTooltip
    },
    methods: {
        async getAllFranchisesAndOrders() {
            try {
                const res = await CommonServices.getAllFranchisesAndOrders({
                    ...this.filters
                });
                console.log(res.data.data)
                if (res.data.status) {
                    this.franchises = res.data.data;
                } else {
                    console.error("Could Not Get All franchises");
                }
            } catch (error) {
                console.error("Could Not Get All franchises", error);
            }
        },
        async saveOrderStatus(order) {
            try {
                const res = await CommonServices.saveShopOrderStatus({
                    so_is_paid: order.so_is_paid,
                    so_is_fullfilled: order.so_is_fullfilled,
                    so_id: order.so_id
                })

                if (!res.data.status) {
                    this.$toast({
                        component: ToastificationContentVue,
                        props: {
                            title: "Could Not Save Order Status",
                            icon: "XIcon",
                            variant: "danger",
                        },
                    });
                    return
                }

                this.$toast({
                    component: ToastificationContentVue,
                    props: {
                        title: "Order Status Saved",
                        icon: "CheckIcon",
                        variant: "success",
                    },
                });

                this.getAllFranchisesAndOrders()

            } catch (error) {
                console.error("Could Not Save Order Status");
            }

        },
        onChange() {
            this.$nextTick(() => {
                this.getAllFranchisesAndOrders();
            });
        },
        onSearch() {
            if (this.debounce) {
                this.debounce = null;
            }
            this.debounce = setTimeout(() => {
                this.onChange();
            }, 1000);
        },
        getDateRangeParams() {
            if (this.$route.query.date_range) {
                this.filters.date_range = this.$route.query.date_range;
            }
        },
        rowClicked(e) {
            console.log(e)
        },
        invoiceClick(id) {
            console.log(id)
            this.$router.push({
                name: "Shop Order Details",
                params: { so_id: id },
            });
        },
        moment,
        resetFilters() {
            this.filters = {
                search: '',
                date_range: '',
                product_id: null,
                is_paid: '',
                is_fulfilled: '',
                is_status: ''
            }
        },
    },
    beforeMount() {
        this.getDateRangeParams();
        this.getAllFranchisesAndOrders();
    },
}
</script>
<style>
.bg-transparent {
    background-color: transparent !important;
    border: none !important;
}

.bg-transparent:hover {
    box-shadow: none !important;
}

.bg-transparent:focus {
    box-shadow: none !important;
    background-color: transparent !important;
    border: none !important;
}
</style>